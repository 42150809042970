import { CInputFile } from "@coreui/react";
import React, { useState } from "react";
import * as XLSX from "xlsx";

const ImportSpreadSheetData = ({ onDataLoad }) => {
  const [fileName, setFileName] = useState("");

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFileName(file.name);
      const reader = new FileReader();

      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });

        // Assuming the first sheet is the one we need
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];

        // Convert to JSON
        let jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: true });

        // Map and format data to match the required structure
        jsonData = jsonData.map((row) => ({
          status: row.status || "active",
          episodeId: row.episodeId || "",
          referenceId: row.referenceId || "",
          imageUrl: row.imageUrl || "",
          start: String(row.start || "0"),
          data: {
            name: row.dataName || "点赞",
            src: row.dataSrc || "/lottie/lottie1/data.json",
          },
          title: row.title || "",
          id: row.id || "",
          interactiveId: row.interactiveId || "",
          currentQuestion: row.currentQuestion || 0,
          options: parseOptions(row.options),
          resourceId: row.resourceId || "",
          actionType: row.actionType || "seek",
          style: row.style || "simple",
          updatedAt: parseTimestamp(row.updatedAt),
          effectId: row.effectId || "",
          createdAt: parseTimestamp(row.createdAt),
          authorId: row.authorId || "",
          type: row.type || "",
          seekTime: row.seekTime || 0,
          end: String(row.end || "0"),
          questions: parseQuestions(row.questions),
        }));

        console.log("Parsed Data:", jsonData);

        // Pass data to parent
        if (onDataLoad) {
          onDataLoad(jsonData);
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const parseOptions = (options) => {
    try {
      const parsed = JSON.parse(options);
      return Array.isArray(parsed)
        ? parsed.map((opt) => ({
            fullImage: opt.fullImage || false,
            imageUrl: opt.imageUrl || "",
            linkUrl: opt.linkUrl || "",
            optId: opt.optId || "",
            text: opt.text || "",
            iconUrl: opt.iconUrl || "",
          }))
        : [];
    } catch {
      return [];
    }
  };

  const parseQuestions = (questions) => {
    try {
      const parsed = JSON.parse(questions);
      return Array.isArray(parsed)
        ? parsed.map((q) => ({
            choicesAnswer: q.choicesAnswer || 0,
            questionId: q.questionId || "",
            question: q.question || "",
            choices: q.choices
              ? q.choices.map((choice) => ({
                  fullImage: choice.fullImage || false,
                  imageUrl: choice.imageUrl || "",
                  text: choice.text || "",
                  iconUrl: choice.iconUrl || "",
                  linkUrl: choice.linkUrl || "",
                  optId: choice.optId || "",
                }))
              : [],
            imageUrl: q.imageUrl || "",
          }))
        : [];
    } catch {
      return [];
    }
  };

  const parseTimestamp = (timestamp) => {
    const now = new Date();
    return timestamp
      ? {
          seconds: Math.floor(new Date(timestamp).getTime() / 1000),
          nanoseconds: 0,
        }
      : {
          seconds: Math.floor(now.getTime() / 1000),
          nanoseconds: 0,
        };
  };

  return (
    <div style={{ padding: "1rem" }}>
      <label
        htmlFor="excel-upload"
        style={{
          cursor: "pointer",
          color: "#ffffff",
          fontWeight: "bold",
        }}
      >
        Upload Excel File
      </label>
      <CInputFile
        id="excel-upload"
        type="file"
        accept=".xlsx, .xls"
        onChange={handleFileUpload}
      />
      <div className="p-2">
        {fileName && <p>Uploaded: {fileName}</p>}
      </div>
    </div>
  );
};

export default ImportSpreadSheetData;
