export const authType = {
  IDLE: "idle",
  LOGGEDIN_LOADING: "loggedin_loading",
  LOGGEDIN_SUCCESS: "loggedin_success",
  LOGGEDIN_FAIL: "loggedin_fail",
  REGISTER_LOADING: "register_loading",
  REGISTER_SUCCESS: "register_success",
  REGISTER_FAIL: "register_fail",
  EMAIL_LOADING: "email_loading",
  EMAIL_SUCCESS: "email_success",
  EMAIL_FAIL: "email_fail",
  SOCIAL_LOADING: "social_loading",
  SOCIAL_SUCCESS: "social_success",
  SOCIAL_FAIL: "social_fail",
  SIGNOUT_LOADING: "signout_loading",
  SIGNOUT_SUCCESS: "signout_success",
  SIGNOUT_FAIL: "signout_fail",
};

export const usersType = {
  IDLE: "idle",
  GETUSERS_LOADING: "getusers_loading",
  GETUSERS_SUCCESS: "getusers_success",
  GETUSERS_FAIL: "getusers_fail",
  GETUSER_LOADING: "getuser_loading",
  GETUSER_SUCCESS: "getuser_success",
  GETUSER_FAIL: "getuser_fail",
  UPDATEUSER_LOADING: "updateuser_loading",
  UPDATEUSER_SUCCESS: "updateuser_success",
  UPDATEUSER_FAIL: "updateuser_fail",
  CREATEUSER_LOADING: "createuser_loading",
  CREATEUSER_SUCCESS:"createuser_success" ,
  CREATEUSER_FAIL : 'createuser_fail',
  UPDATESELECTEDUSERS_LOADING: "updateselectedusers_loading",
  UPDATESELECTEDUSERS_SUCCESS: "updateselectedusers_success",
  UPDATESELECTEDUSERS_FAIL: "updateselectedusers_fail",
  DELETESELECTEDUSERS_LOADING: "deleteselectedusers_loading",
  DELETESELECTEDUSERS_SUCCESS: "deleteselectedusers_success",
  DELETESELECTEDUSERS_FAIL: "deleteselectedusers_fail",
};

export const settingsType = {
  IDLE: "idle",
  GETSETTINGS_LOADING: "getsettings_loading",
  GETSETTINGS_SUCCESS: "getsettings_success",
  GETSETTINGS_FAIL: "getsettings_fail",
  UPDATESETTINGS_LOADING: "updatesettings_loading",
  UPDATESETTINGS_SUCCESS: "updatesettings_success",
  UPDATESETTINGS_FAIL: "updatesettings_fail",
};

export const organizationsType = {
  IDLE: "idle",
  GETORGANIZATION_LOADING: "getorganization_loading",
  GETORGANIZATION_SUCCESS: "getorganization_success",
  GETORGANIZATION_FAIL: "getorganization_fail",
  GETORGANIZATIONS_LOADING: "getorganizations_loading",
  GETORGANIZATIONS_SUCCESS: "getorganizations_success",
  GETORGANIZATIONS_FAIL: "getorganizations_fail",
  GETORGCHANNELS_LOADING: "getorgchannels_loading",
  GETORGCHANNELS_SUCCESS: "getorgchannels_success",
  GETORGCHANNELS_FAIL: "getorgchannels_fail",
  GETORGCHANNELGROUPSID_LOADING: "getorgchannelgroupsid_loading",
  GETORGCHANNELGROUPSID_SUCCESS: "getorgchannelgroupsid_success",
  GETORGCHANNELGROUPSID_FAIL: "getorgchannelgroupsid_fail",
  GETORGCHANNELGROUPS_LOADING: "getorgchannelgroups_loading",
  GETORGCHANNELGROUPS_SUCCESS: "getorgchannelgroups_success",
  GETORGCHANNELGROUPS_FAIL: "getorgchannelgroups_fail",
  CREATEORGANIZATION_LOADING: "createorganization_loading",
  CREATEORGANIZATION_SUCCESS: "createorganization_success",
  CREATEORGANIZATION_FAIL: "createorganization_fail",
  UPDATEORGANIZATION_LOADING: "updateorganization_loading",
  UPDATEORGANIZATION_SUCCESS: "updateorganization_success",
  UPDATEORGANIZATION_FAIL: "updateorganization_fail",
  DELETEORGANIZATION_LOADING: "deleteorganization_loading",
  DELETEORGANIZATION_SUCCESS: "deleteorganization_success",
  DELETEORGANIZATION_FAIL: "deleteorganization_fail",
  ADDUSERTOORGANIZATION_LOADING: "addusertoorganization_loading",
  ADDUSERTOORGANIZATION_SUCCESS: "addusertoorganization_success",
  ADDUSERTOORGANIZATION_FAIL: "addusertoorganization_fail",
  REMOVEUSERFROMORGANIZATION_LOADING: "removeuserfromorganization_loading",
  REMOVEUSERFROMORGANIZATION_SUCCESS: "removeuserfromorganization_success",
  REMOVEUSERFROMORGANIZATION_FAIL: "removeuserfromorganization_fail",
  UPDATESELECTEDORGANIZATIONS_LOADING: "updateselectedorganizations_loading",
  UPDATESELECTEDORGANIZATIONS_SUCCESS: "updateselectedorganizations_success",
  UPDATESELECTEDORGANIZATIONS_FAIL: "updateselectedorganizations_fail",
  DELETESELECTEDORGANIZATIONS_LOADING: "deleteselectedorganizations_loading",
  DELETESELECTEDORGANIZATIONS_SUCCESS: "deleteselectedorganizations_success",
  DELETESELECTEDORGANIZATIONS_FAIL: "deleteselectedorganizations_fail",
};

export const channelsType = {
  IDLE: "idle",
  GETCHANNELS_LOADING: "getchannels_loading",
  GETCHANNELS_SUCCESS: "getchannels_success",
  GETCHANNELS_FAIL: "getchannels_fail",
  GETCHANNEL_LOADING: "getchannel_loading",
  GETCHANNEL_SUCCESS: "getchannel_success",
  GETCHANNEL_FAIL: "getchannel_fail",
  CREATECHANNEL_LOADING: "createchannel_loading",
  CREATECHANNEL_SUCCESS: "createchannel_success",
  CREATECHANNEL_FAIL: "createchannel_fail",
  UPDATECHANNEL_LOADING: "updatechannel_loading",
  UPDATECHANNEL_SUCCESS: "updatechannel_success",
  UPDATECHANNEL_FAIL: "updatechannel_fail",
  DELETECHANNEL_LOADING: "deletechannel_loading",
  DELETECHANNEL_SUCCESS: "deletechannel_success",
  DELETECHANNEL_FAIL: "deletechannel_fail",
  UPDATESELECTEDCHANNELS_LOADING: "updateselectedchannels_loading",
  UPDATESELECTEDCHANNELS_SUCCESS: "updateselectedchannels_success",
  UPDATESELECTEDCHANNELS_FAIL: "updateselectedchannels_fail",
  DELETESELECTEDCHANNELS_LOADING: "deleteselectedchannels_loading",
  DELETESELECTEDCHANNELS_SUCCESS: "deleteselectedchannels_success",
  DELETESELECTEDCHANNELS_FAIL: "deleteselectedchannels_fail",
};

export const reviewsType = {
  GETCHANNELREVIEWS_LOADING: "getchannelreviews_loading",
  GETCHANNELREVIEWS_SUCCESS: "getchannelreviews_success",
  GETCHANNELREVIEWS_FAIL: "getchannelreviews_fail",
  UPDATECHANNELREVIEWS_LOADING: "updatechannelreviews_loading",
  UPDATECHANNELREVIEWS_SUCCESS: "updatechannelreviews_success",
  UPDATECHANNELREVIEWS_FAIL: "updatechannelreviews_fail",
}

export const channelGroupsType = {
  IDLE: "idle",
  //get channel group(s)
  GETCHANNELGROUPS_LOADING: "getchannelgroups_loading",
  GETCHANNELGROUPS_SUCCESS: "getchannelgroups_success",
  GETCHANNELGROUPS_FAIL: "getchannelgroups_fail",
  //group channel
  GETGROUPCHANNELS_LOADING: "getgroupchannels_loading",
  GETGROUPCHANNELS_SUCCESS: "getgroupchannels_success",
  GETGROUPCHANNELS_FAIL: "getgroupchannels_fail",
  //channel group id
  GETCHANNELGROUPSID_LOADING: "getchannelgroupsid_loading",
  GETCHANNELGROUPSID_SUCCESS: "getchannelgroupsid_success",
  GETCHANNELGROUPSID_FAIL: "getchannelgroupsid_fail",
  //get channel group
  GETCHANNELGROUP_LOADING: "getchannelgroup_loading",
  GETCHANNELGROUP_SUCCESS: "getchannelgroup_success",
  GETCHANNELGROUP_FAIL: "getchannelgroup_fail",
  //create channel group
  CREATECHANNELGROUP_LOADING: "createchannelgroup_loading",
  CREATECHANNELGROUP_SUCCESS: "createchannelgroup_success",
  CREATECHANNELGROUP_FAIL: "createchannelgroup_fail",
  //update channel group
  UPDATECHANNELGROUP_LOADING: "updatechannelgroup_loading",
  UPDATECHANNELGROUP_SUCCESS: "updatechannelgroup_success",
  UPDATECHANNELGROUP_FAIL: "updatechannelgroup_fail",
  //delete channel group
  DELETECHANNELGROUP_LOADING: "deletechannelgroup_loading",
  DELETECHANNELGROUP_SUCCESS: "deletechannelgroup_success",
  DELETECHANNELGROUP_FAIL: "deletechannelgroup_fail",
  //delete  group channel
  DELETEGROUPCHANNEL_LOADING: "deletegroupchannel_loading",
  DELETEGROUPCHANNEL_SUCCESS: "deletegroupchannel_success",
  DELETEGROUPCHANNEL_FAIL: "deletegroupchannel_fail",
  //update selected channel group
  UPDATESELECTEDCHANNELGROUPS_LOADING: "updateselectedchannelgroups_loading",
  UPDATESELECTEDCHANNELGROUPS_SUCCESS: "updateselectedchannelgroups_success",
  UPDATESELECTEDCHANNELGROUPS_FAIL: "updateselectedchannelgroups_fail",
  //delete selected channel group
  DELETESELECTEDCHANNELGROUPS_LOADING: "deleteselectedchannelgroups_loading",
  DELETESELECTEDCHANNELGROUPS_SUCCESS: "deleteselectedchannelgroups_success",
  DELETESELECTEDCHANNELGROUPS_FAIL: "deleteselectedchannelgroups_fail",
};

export const roleType = {
  ADMIN: "admin",
  EDITOR: "editor",
  OPERATOR: "operator",
  ANALYST: "analyst",
};

export const interactiveType = {
  IDLE: "idle",
  GETCHANNELINTERACTIVITIES_LOADING: "getchannelinteractivities_loading",
  GETCHANNELINTERACTIVITIES_SUCCESS: "getchannelinteractivities_success",
  GETCHANNELINTERACTIVITIES_FAIL: "getchannelinteractivities_fail",
  CREATEINTERACTIVITY_LOADING: "createinteractivity_loading",
  CREATEINTERACTIVITY_SUCCESS: "createinteractivity_success",
  CREATEINTERACTIVITY_FAIL: "createinteractivity_fail",
  UPDATEINTERACTIVITY_LOADING: "updateinteractivity_loading",
  UPDATEINTERACTIVITY_SUCCESS: "updateinteractivity_success",
  UPDATEINTERACTIVITY_FAIL: "updateinteractivity_fail",
  DELETEINTERACTIVITY_LOADING: "deleteinteractivity_loading",
  DELETEINTERACTIVITY_SUCCESS: "deleteinteractivity_success",
  DELETEINTERACTIVITY_FAIL: "deleteinteractivity_fail",
  GETPROFILEINTERACTIONS_LOADING: "getprofileinteractions_loading",
  GETPROFILEINTERACTIONS_SUCCESS: "getprofileinteractions_success",
  GETPROFILEINTERACTIONS_FAIL: "getprofileinteractions_fail",
};

export const vodInteractiveType = {
  IDLE: "idle",
  GETVODINTERACTIVITIES_LOADING: "getchannelinteractivities_loading",
  GETVODINTERACTIVITIES_SUCCESS: "getchannelinteractivities_success",
  GETVODINTERACTIVITIES_FAIL: "getchannelinteractivities_fail",
  CREATEVODINTERACTIVITY_LOADING: "createinteractivity_loading",
  CREATEVODINTERACTIVITY_SUCCESS: "createinteractivity_success",
  CREATEVODINTERACTIVITY_FAIL: "createinteractivity_fail",
  UPDATEVODINTERACTIVITY_LOADING: "updateinteractivity_loading",
  UPDATEVODINTERACTIVITY_SUCCESS: "updateinteractivity_success",
  UPDATEVODINTERACTIVITY_FAIL: "updateinteractivity_fail",
  DELETEVODINTERACTIVITY_LOADING: "deleteinteractivity_loading",
  DELETEVODINTERACTIVITY_SUCCESS: "deleteinteractivity_success",
  DELETEVODINTERACTIVITY_FAIL: "deleteinteractivity_fail",
  // GETPROFILEINTERACTIONS_LOADING: "getprofileinteractions_loading",
  // GETPROFILEINTERACTIONS_SUCCESS: "getprofileinteractions_success",
  // GETPROFILEINTERACTIONS_FAIL: "getprofileinteractions_fail",
};

export const resourceType = {
  IDLE: "idle",
  GETRESOURCES_LOADING: "getresources_loading",
  GETRESOURCES_SUCCESS: "getresources_success",
  GETRESOURCES_FAIL: "getresources_loading",
  GETRESOURCE_LOADING: "getresource_loading",
  GETRESOURCE_SUCCESS: "getresource_success",
  GETRESOURCE_FAIL: "getresource_loading",
  CREATERESOURCE_LOADING: "createresource_loading",
  CREATERESOURCE_SUCCESS: "createresource_success",
  CREATERESOURCE_FAIL: "createresource_fail",
  UPDATERESOURCE_LOADING: "updateesource_loading",
  UPDATERESOURCE_SUCCESS: "updateesource_success",
  UPDATERESOURCE_FAIL: "updateesource_fail",
  DELETERESOURCE_LOADING: "deleteesource_loading",
  DELETERESOURCE_SUCCESS: "deleteesource_success",
  DELETERESOURCE_FAIL: "deleteesource_fail",
  GETEPISODES_LOADING: "getepisodes_loading",
  GETEPISODES_SUCCESS: "getepisodes_success",
  GETEPISODES_FAIL: "getepisodse_fail",
  GETEPISODE_LOADING: "getepisode_loading",
  GETEPISODE_SUCCESS: "getepisode_success",
  GETEPISODE_FAIL: "getepisode_fail",
  CREATEEPISODE_LOADING: "createepisode_loading",
  CREATEEPISODE_SUCCESS: "createepisode_success",
  CREATEEPISODE_FAIL: "createepisode_fail",
  UPDATEEPISODE_LOADING: "updateepisode_loading",
  UPDATEEPISODE_SUCCESS: "updateepisode_success",
  UPDATEEPISODE_FAIL: "updateepisode_fail",
  DELETEEPISODE_LOADING: "deleteepisode_loading",
  DELETEEPISODE_SUCCESS: "deleteepisode_success",
  DELETEEPISODE_FAIL: "deleteepisode_fail",
  //update selected RESOURCES
  UPDATESELECTEDRESOURCES_LOADING: "updateselectedresources_loading",
  UPDATESELECTEDRESOURCES_SUCCESS: "updateselectedresources_success",
  UPDATESELECTEDRESOURCES_FAIL: "updateselectedresources_fail",
  //delete selected RESOURCES
  DELETESELECTEDRESOURCES_LOADING: "deleteselectedresources_loading",
  DELETESELECTEDRESOURCES_SUCCESS: "deleteselectedresources_success",
  DELETESELECTEDRESOURCES_FAIL: "deleteselectedresources_fail",
};

export const analyticsType = {
  IDLE: "idle",
  GETCHANNELANALYTICS_LOADING: "getchannelanalytics_loading",
  GETCHANNELANALYTICS_SUCCESS: "getchannelanalytics_success",
  GETCHANNELANALYTICS_FAIL: "getchannelanalytics_fail",
};